import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  getServicesList({
    page = 1, perPage = 5, filter, search,
  }) {
    const instWithCred = axios.create({
      baseURL: domains.SERVICES_API,
    });
    const filterQuery = filter ? `&filter=${filter}` : '';
    const searchQuery = search ? `&search=${search}` : '';
    const sortQuery = '&sort={"createdAt": -1}';
    return instWithCred.get(`services?page=${page}&per_page=${perPage}${filterQuery}${searchQuery}${sortQuery}`);
  },
  getServicesTypeListByCountry({ page = 1, perPage = 5, filter }) {
    const instWithCred = axios.create({
      baseURL: domains.SERVICES_API,
    });
    const filterQuery = filter ? `&filter=${filter}` : '';
    return instWithCred.get(`services/country-service-types?page=${page}&per_page=${perPage}${filterQuery}`);
  },
  getServicesManagerForm({ page = 1, perPage = 5, filter }) {
    const instWithCred = axios.create({
      baseURL: domains.SERVICES_API,
    });
    const filterQuery = filter ? `&filter=${filter}` : '';
    return instWithCred.get(`services/service-management-forms?page=${page}&per_page=${perPage}${filterQuery}`);
  },
  getOwnersDirectorsCountriesServicesList({
    page = 1, perPage = 5, directorCountry,
    ownerCountry, filter,
  }) {
    const instWithCred = axios.create({
      baseURL: domains.SERVICES_API,
    });
    const directorCountryQueryParams = directorCountry ? `&directorCountry=${directorCountry}` : '';
    const ownerCountryQueryParams = ownerCountry ? `&ownerCountry=${ownerCountry}` : '';
    const filterQuery = filter ? `&filter=${filter}` : '';
    const queryParams = `${directorCountryQueryParams}${ownerCountryQueryParams}${filterQuery}`;
    return instWithCred.get(`services/owners-directors-countries?page=${page}&per_page=${perPage}${queryParams}`);
  },
  getServiceById(id) {
    const instWithCred = axios.create({
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.get(`services/${id}`);
  },
  deleteService(id) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.delete(`services/${id}`);
  },
  addService(service) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.post('services', service);
  },
  updateService(service) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.patch(`services/${service._id}`, { ...service, _id: undefined });
  },
  copyService(id) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.post(`services/copy-service/${id}`);
  },
};
